// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-act-js": () => import("./../../../src/pages/info/act.js" /* webpackChunkName: "component---src-pages-info-act-js" */),
  "component---src-pages-info-event-js": () => import("./../../../src/pages/info/event.js" /* webpackChunkName: "component---src-pages-info-event-js" */),
  "component---src-pages-info-goods-js": () => import("./../../../src/pages/info/goods.js" /* webpackChunkName: "component---src-pages-info-goods-js" */),
  "component---src-pages-info-hotel-js": () => import("./../../../src/pages/info/hotel.js" /* webpackChunkName: "component---src-pages-info-hotel-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-info-see-js": () => import("./../../../src/pages/info/see.js" /* webpackChunkName: "component---src-pages-info-see-js" */),
  "component---src-pages-tagamate-etori-js": () => import("./../../../src/pages/tagamate/Etori.js" /* webpackChunkName: "component---src-pages-tagamate-etori-js" */),
  "component---src-pages-tagamate-hashimoto-js": () => import("./../../../src/pages/tagamate/Hashimoto.js" /* webpackChunkName: "component---src-pages-tagamate-hashimoto-js" */),
  "component---src-pages-tagamate-iida-js": () => import("./../../../src/pages/tagamate/Iida.js" /* webpackChunkName: "component---src-pages-tagamate-iida-js" */),
  "component---src-pages-tagamate-js": () => import("./../../../src/pages/tagamate.js" /* webpackChunkName: "component---src-pages-tagamate-js" */),
  "component---src-pages-tagamate-kimura-js": () => import("./../../../src/pages/tagamate/Kimura.js" /* webpackChunkName: "component---src-pages-tagamate-kimura-js" */),
  "component---src-pages-tagamate-murata-js": () => import("./../../../src/pages/tagamate/Murata.js" /* webpackChunkName: "component---src-pages-tagamate-murata-js" */),
  "component---src-pages-tagamate-ohizumi-js": () => import("./../../../src/pages/tagamate/Ohizumi.js" /* webpackChunkName: "component---src-pages-tagamate-ohizumi-js" */),
  "component---src-pages-tagamate-sakurai-js": () => import("./../../../src/pages/tagamate/Sakurai.js" /* webpackChunkName: "component---src-pages-tagamate-sakurai-js" */),
  "component---src-pages-tagamate-tanigawa-js": () => import("./../../../src/pages/tagamate/Tanigawa.js" /* webpackChunkName: "component---src-pages-tagamate-tanigawa-js" */),
  "component---src-pages-tagamate-urakawa-js": () => import("./../../../src/pages/tagamate/Urakawa.js" /* webpackChunkName: "component---src-pages-tagamate-urakawa-js" */),
  "component---src-pages-tagamate-utakata-js": () => import("./../../../src/pages/tagamate/Utakata.js" /* webpackChunkName: "component---src-pages-tagamate-utakata-js" */),
  "component---src-pages-tagamate-watabe-js": () => import("./../../../src/pages/tagamate/Watabe.js" /* webpackChunkName: "component---src-pages-tagamate-watabe-js" */),
  "component---src-pages-tagamate-yago-js": () => import("./../../../src/pages/tagamate/Yago.js" /* webpackChunkName: "component---src-pages-tagamate-yago-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

